export const APP_ENV = "live";

const urlLookUp = {
  gateway: {
    local: "http://localhost:3004",
    staging: "https://dashboard.tag8.co.in",
    live: "https://dashboard.tag8.in",
  },
  genie: {
    local: "http://localhost:3020",
    staging: "https://genie.tag8.co.in",
    live: "https://genie.tag8.in",
  },
  dolphin: {
    local: "https://dolphin.tag8.co.in",
    staging: "https://dolphin.tag8.co.in",
    live: "https://dolphin.tag8.in",
  },
  key: {
    local: "http://localhost:3050",
    staging: "https://key.tag8.co.in",
    live: "https://key.tag8.in",
  },
  bag: {
    local: "http://localhost:3070",
    staging: "https://bag.tag8.co.in",
    live: "https://bag.tag8.in",
  },
  wallet: {
    local: "http://localhost:3080",
    staging: "https://wallet.tag8.co.in",
    live: "https://wallet.tag8.in",
  },
  generic: {
    local: "http://localhost:3030",
    staging: "https://securevaluables.tag8.co.in",
    live: "https://securevaluables.tag8.in",
  },
  earphone: {
    local: "http://localhost:3079",
    staging: "https://gadgets.tag8.co.in",
    live: "https://gadgets.tag8.in",
  },
  passport: {
    local: "http://localhost:3093",
    staging: "https://passport.tag8.co.in",
    live: "https://passport.tag8.in",
  },
  human: {
    local: "http://localhost:3268",
    staging: "https://people.tag8.co.in",
    live: "https://people.tag8.in",
  },
  appleuri: {
    local: "https://dashboard.tag8.co.in",
    staging: "https://dashboard.tag8.co.in",
    live: "https://dashboard.tag8.in",
  },
  bag_frontend: {
    local: "http://localhost:3103",
    staging: "https://bag.tag8.co.in",
    live: "https://bag.tag8.in",
  },
  gateway_frontend: {
    local: "http://localhost:3000",
    staging: "https://dashboard.tag8.co.in",
    live: "https://dashboard.tag8.in",
  },
  passport_frontend: {
    local: "http://localhost:3107",
    staging: "https://passport.tag8.co.in",
    live: "https://passport.tag8.in",
  },
};

export const switchUrls = (domain) => {
  switch (APP_ENV) {
    case "local":
      return urlLookUp[domain][APP_ENV];
    case "staging":
      return urlLookUp[domain][APP_ENV];
    case "live":
      return urlLookUp[domain][APP_ENV];
    default:
      break;
  }
};
